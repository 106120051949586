<template>
    <table ref="tableEl" class="display">
        <thead>
            <tr>
                <th>Name</th>
                <th>Organization</th>
                <th>Certification</th>
                <th>Certification Date</th>
            </tr>
        </thead>
        <div>
            <div class="loader" v-if="loading"></div>
        </div>
    </table>
</template>

<script setup>
    import { ref, onMounted, nextTick } from 'vue';
    import DataTable from 'datatables.net-dt';
    import 'datatables.net-dt/css/dataTables.dataTables.min.css';

    const loading = ref(false);
    const tableEl = ref(null);

    async function getStudents(){
        const response = await fetch('https://storage.googleapis.com/mscertifications.trisotech.com/participants.json');
        let data = await response.json();

        return data.filter(s => s.name && s.organization && s.certification && s.date)
            .map(s => {
                return {
                    Name: s.name,
                    Organization: s.organization,
                    Certification: s.certification,
                    'Certification Date':new Date(s.date).toISOString().split('T')[0]
                };
            }).sort((a,b) => b.date - a.date);
    }

    onMounted(async () => {
        loading.value = true;
        let students = [];
        try {
            students = await getStudents();
        } finally {
            loading.value = false;
        }

        await nextTick();

        new DataTable(tableEl.value, {
            columns: [
                {data: 'Name', orderable: false},
                {data: 'Organization', orderable: false},
                {data: 'Certification', orderable: false},
                {data: 'Certification Date'}
            ],
            data: students,
            order: [
                [3, 'desc']
            ],
            pageLength: 50
        });
    });

</script>

<style scoped lang="less">

    .loader {
        border: 4px solid #e4e4e4;
        border-top: 4px solid #5f6367;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
        margin: 50px auto;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>

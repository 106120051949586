import { createApp } from "vue";
import Table from "./components/table.vue";

export class CertificationTable {
    constructor(el, url) {
        const app = createApp(Table);
        app.mount(el);
    }
}

let container = document.getElementById("students");

if (container) {
    new CertificationTable("#students");
}

import '@splidejs/splide/css';

import '../css/styles.css';
import '../less/slider.less';

import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import './certifications.js';

var mainSlider = document.getElementById('mainSlider');
if (mainSlider) {
    new Splide(mainSlider, {
        type: 'loop',
        autoplay: true
    }).mount();
}

var clientSlider = document.getElementById('clientsSlider');
if (clientSlider) {
    new Splide(clientSlider, {
        type: 'loop',
        drag   : 'free',
        focus  : 'center',
        perPage: 5,
        autoScroll: {
            speed: 1
        },
        pagination: false
    }).mount({ AutoScroll });
}

let searchInput = document.getElementById('searchInput');
if (searchInput) {
    searchInput.addEventListener('keyup', e => {
        if (event.key === "Enter") {
            let value = e.target.value;
            if (value) {
                let search = new URLSearchParams();
                search.append('q', value);
                window.location.href = (new URL(`/search/?${search.toString()}`, window.location.origin)).toString();
            }
        }

    });
}
